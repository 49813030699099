import Repository from './repository'

const resource = '/expedicao'

export default {

  Update (pedido, qtpedido, item, subItem) {
    return Repository.put(`${resource}`, { cdOrcamento: pedido, qtSeparada: qtpedido, cdItem: item, cdSubItem: subItem })
  },
  Save (data) {
    return Repository.post(`${resource}`, data)
  },
}
