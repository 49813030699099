import Repository from './repository'

const resource = '/pesador'

export default {

  getList () {
    return Repository.get(`${resource}`)
  },
  getListSupervisor () {
    return Repository.post(`${resource}/getSupervisor`)
  },

  getPorUsuario (usuario, loja) {
    return Repository.get(`${resource}/getPorUsuario?cdusuario=${usuario}&loja=${loja}`)
  },
}
