<template>
  <v-card>
    <v-container fluid>
      <v-toolbar
        dark
        color="primary"
        class="pl-0 ml-0"
        elevation="10"
        rounded
      >
        <v-toolbar-title>
          Item(ns) do Orçamento - <strong>{{ orcamentoformatado }}</strong>
        </v-toolbar-title>
      </v-toolbar>
      <div
        class="mb-6"
      />
      <!-- VALIDAÇÃO SE VAI SEPARAR ITEM E MOVIMENTAR O ESTOQUE -->
      <div v-if="flseparar == false">
        <v-data-table
          v-model="selected"
          item-key="item"
          dense
          :headers="headers"
          :items="listaItens"
          show-select
        >
          <template v-slot:header.data-table-select />
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              :value="isSelected"
              :disabled="isSelected"
              @input="select($event)"
            />
          </template>
          <template v-slot:item.vlUnitario="{ item }">
            {{ formatPrice(item.vlUnitario) }}
          </template>
          <template v-slot:item.vlUnitario="{ item }">
            {{ formatPrice(item.vlUnitario) }}
          </template>
          <template v-slot:item.vlPesoUnd="{ item }">
            {{ formatPrice(item.vlPesoUnd) }}
          </template>
          <template v-slot:item.qtVenda="{ item }">
            {{ formatPrice(item.qtVenda) }}
          </template>
          <template v-slot:item.qtSeparado="{ item }">
            <strong
              v-if="item.qtSeparado === 0"
            >{{ formatPrice(item.qtSeparado) }}</strong>
            <strong
              v-else-if="item.qtSeparado < item.qtVenda "
              class="orange text-h4"
            >{{ formatPrice(item.qtSeparado) }}</strong>
            <strong
              v-else
              class="blue--text text--darken-4 text-h4"
            >{{ formatPrice(item.qtSeparado) }}</strong>
          </template>
        <!-- <template
          v-if="flselecionarpesador"
          v-slot:item.actions="{ item }"
        >
          <comboboxPesador
            v-if="item.cdPesador == 0"
            class="pt-6 pb-0"
            :itemobj="item"
            @onSelecionarPesador="onSelecionarPesador"
          />
          <v-icon
            v-if="item.cdPesador != 0"
            color="blue darken-4"
            class="d-flex justify-center"
          >
            mdi-thumb-up-outline
          </v-icon>
        </template> -->
        </v-data-table>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="4"
            lg="4"
          >
            <v-text-field
              v-model="conferentePesador"
              autofocus
              hide-details
              label="INFORME O CONFERENTE/PESADOR"
              type="password"
              autocomplete="off"
              @keydown.enter="getConferentePesador"
            >
              <template v-slot:append>
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      medium
                      color="primary"
                      class="mt-0 pt-0"
                      @click="dialogCodigoConferente = true"
                    >
                      <v-icon v-on="on">
                        mdi-barcode
                      </v-icon>
                    </v-btn>
                  </template>
                  Pesquisar
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- END - VALIDAÇÃO SE VAI SEPARAR ITEM E MOVIMENTAR O ESTOQUE -->
      </div>
      <div v-else>
        <!-- <v-card
          v-for="(item, index) in listaItens"
          :key="index"
          class="my-5"
        >
          <v-card
            v-if="index == 0"
            class="mb-5"
          >
            <v-row class="text-sm-body-2">
              <v-col
                md="2"
                lg="1"
              >
                Código
              </v-col>
              <v-col
                md="6"
                lg="6"
              >
                Descricao
              </v-col>
              <v-col
                md="2"
                lg="1"
                class="text-right"
              >
                Vl.Unit.
              </v-col>
              <v-col
                md="1"
                lg="1"
              >
                Unidade
              </v-col>
              <v-col
                md="1"
                lg="1"
                class="text-right"
              >
                Peso Und.
              </v-col>
              <v-col
                md="2"
                lg="1"
                class="text-right"
              >
                Qt. Venda
              </v-col>
              <v-col
                md="2"
                lg="1"
                class="text-right"
              >
                Qt. Separada
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-col
              cols="12"
              md="2"
              lg="1"
              class="text-center"
            >
              {{ item.item }}
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              {{ item.nmItem }}
            </v-col>
            <v-col
              cols="12"
              md="2"
              lg="1"
              class="text-right"
            >
              {{ formatPrice(item.vlUnitario) }}
            </v-col>
            <v-col
              cols="12"
              md="1"
              lg="1"
            >
              {{ item.cdUnidade }}
            </v-col>
            <v-col
              cols="12"
              md="1"
              lg="1"
              class="text-right"
            >
              {{ formatPrice(item.vlPesoUnd) }}
            </v-col>
            <v-col
              cols="12"
              md="2"
              lg="1"
              class="text-right"
            >
              {{ formatPrice(item.qtVenda) }}
            </v-col>
            <v-col
              cols="12"
              md="2"
              lg="1"
              class="text-right pr-5"
            >
              {{ formatPrice(item.qtSeparado) }}
            </v-col>
          </v-row>
        </v-card> -->
        <v-data-table
          dense
          :headers="headers"
          :items="listaItens"
        >
          <template v-slot:item.vlUnitario="{ item }">
            {{ formatPrice(item.vlUnitario) }}
          </template>
          <template v-slot:item.vlPesoUnd="{ item }">
            {{ formatPrice(item.vlPesoUnd) }}
          </template>
          <template v-slot:item.qtVenda="{ item }">
            {{ formatPrice(item.qtVenda) }}
          </template>
          <template v-slot:item.qtSeparado="{ item }">
            <strong
              v-if="item.qtSeparado === 0"
            >{{ formatPrice(item.qtSeparado) }}</strong>
            <strong
              v-else-if="item.qtSeparado < item.qtVenda "
              class="orange text-h4"
            >{{ formatPrice(item.qtSeparado) }}</strong>
            <strong
              v-else
              class="blue--text text--darken-4 text-h4"
            >{{ formatPrice(item.qtSeparado) }}</strong>
          </template>
          <template v-slot:item.qtDigitada="{ item }">
            <!-- {{ formatPrice(item.qtDigitada) }} -->
            <v-text-field
              v-model="item.qtDigitada"
              v-currency="currencyAjuste"
              label="Outlined"
              solo
              class="pa-1 text-right"
              dense
              hide-details
            />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="liberadaestorno && item.qtSeparado !== 0"
              large
              color="primary"
              class="mr-2"
              @click="estornarseparacao(item)"
            >
              mdi-refresh
            </v-icon>
          </template>
        </v-data-table>
        <v-row>
          <v-col>
            <v-btn
              color="primary accent-4"
              @click="onSalvarItemPesador"
            >
              <v-icon left>
                mdi-content-save
              </v-icon> Salvar
            </v-btn>
            <v-btn
              color="primary accent-4"
              @click="onPedirImpressao"
            >
              <v-icon left>
                mdi-printer-pos
              </v-icon> Imprimir
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-model="dialogItemExpedicao"
      max-width="800"
    >
      <modalItemSeparacao
        v-if="dialogItemExpedicao"
        :item="itemSelecionado"
        :pedido="idorcamento"
        @onInformadaSeparacao="onInformadaSeparacao"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogExpedicaoLiberacao"
      max-width="600"
    >
      <modalPesador
        v-if="dialogExpedicaoLiberacao"
        supervisor
      />
    </v-dialog>
    <!-- <v-card-actions class="pt-0 pl-4">
      <v-btn
        color="primary accent-4"
        @click="onSalvarItemPesador"
      >
        <v-icon left>
          mdi-content-save
        </v-icon> Salvar
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
<script>
  import orcamentoRep from './../../components/services/orcamentoRepository'
  import itempesadorRep from './../../components/services/itemPesadorRepository'
  import modalItemSeparacao from './modal-item-expedicao-separacao.vue'
  import modalPesador from '../componentes/modal-expedicao-liberacao.vue'
  import pesadorRep from './../../components/services/pesadorRepository'
  import impressaoRep from './../../components/services/impressaoRepository'
  // import comboboxPesador from '../componentes/componente-combobox-pesador.vue'
  export default {
    components: {
      modalItemSeparacao,
      modalPesador,
      // comboboxPesador,
    },
    props: {
      idorcamento: {
        type: Number,
        required: true,
      },
      orcamentoformatado: {
        type: String,
        required: true,
      },
      flexpedicao: {
        type: Boolean,
        default: false,
      },
      flselecionarpesador: {
        type: Boolean,
        default: false,
      },
      flmovimentarestoque: {
        type: Boolean,
        default: false,
      },
      flseparar: {
        type: Boolean,
        default: false,
      },
      liberadaestorno: {
        type: Boolean,
        default: false,
      },
      lojaselecionada: {
        type: Number,
        required: true,
      },
      codigovendedor: {
        type: Number,
        required: true,
      },
      stacao: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        selected: [],
        listaItens: [],
        conferentePesador: '',
        dialogItemExpedicao: false,
        dialogExpedicaoLiberacao: false,
        selectTodos: false,
        headers: [
          {
            text: 'Item',
            align: 'start',
            value: 'item',
            width: 80,
          },
          {
            text: 'Descricao',
            value: 'nmItem',
            width: 230,
          },
          {
            text: 'Rua',
            value: 'cdRua',
            width: 80,
          },
          {
            text: 'Prateleira',
            value: 'cdPrateleira',
            width: 100,
          },
          {
            text: 'Gaveta',
            value: 'cdGaveta',
            width: 100,
          },
          {
            text: 'Vl. Unit.',
            align: 'end',
            value: 'vlUnitario',
            width: 80,
          },
          {
            text: 'Unid.',
            align: 'start',
            value: 'cdUnidade',
            width: 70,
          },
          {
            text: 'Peso',
            align: 'end',
            value: 'vlPesoUnd',
            width: 80,
          },
          {
            text: 'Qtd.',
            align: 'end',
            value: 'qtVenda',
            width: 100,
          },
          {
            text: 'Qtd. Separada',
            align: 'end',
            value: 'qtSeparado',
            width: 100,
          },
          { text: 'Qt. Digitada', value: 'qtDigitada', width: 140, sortable: false },
          { text: 'Pesador', value: 'nmPesador', width: 120, sortable: false },
          { text: 'Data e Hora', value: 'dtRecebimentoFormatado', width: 120, sortable: false },
          // { text: 'Pesador', value: 'actions', width: 200, sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        itemSelecionado: {},
        listaItemPesador: [],
        itemPesador: [],
        cdPesadorSelecionado: 0,
        dialogLoja: false,
        loja: 0,
        dadosLoja: {},
        currencyAjuste: {
          currency: null,
          valueAsInteger: true,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
      }
    },
    created () {
      this.get()
    },
    methods: {
      async get () {
        try {
          this.$store.commit('setSplashScreen', true)
          this.listaItens = []
          await orcamentoRep.ItensPedido(this.idorcamento).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaItens = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
        // CARREGAR ITEMPESADOR - SABER QUAL FOI JÁ SEPARADO
        await itempesadorRep.getId(this.idorcamento).then(response => {
          this.$store.commit('setSplashScreen', false)
          this.listaItemPesador = response.data.result
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
        var arrayNovo = this.listaItens
        if (this.stacao === 'E') {
          if (this.listaItemPesador.length === 0) {
            this.$swal({
              icon: 'warning',
              text: 'Não existe itens a ser devolvido(s)',
            })
            this.$emit('onSairExpedicao')
          }
        }

        for (let index = 0; index < this.listaItemPesador.length; index++) {
          var itemPesador = this.listaItemPesador[index].cdItem + this.listaItemPesador[index].cdSubItem
          for (let j = 0; j < arrayNovo.length; j++) {
            // if (this.flseparar === true) {
            if (arrayNovo[j].item.trim() === itemPesador) {
              // arrayNovo.splice(j, 1)
              if (this.stacao === 'S') {
                this.selected.push(arrayNovo[j])
                arrayNovo[j].disabled = true
              }
              arrayNovo[j].cdPesador = this.listaItemPesador[index].cdPesador
              arrayNovo[j].nmPesador = this.listaItemPesador[index].nmPesador
              arrayNovo[j].dtRecebimentoFormatado = this.listaItemPesador[index].dtRecebimentoFormatado
              arrayNovo[j].qtSeparado += this.listaItemPesador[index].qtSeparado
            }
            // } else {
            //   arrayNovo[j].qtSeparado += this.listaItemPesador[index].qtSeparado
            // }
            // arrayNovo[j].disabled = true
            // this.selected.push(arrayNovo[j])
            // arrayNovo[j].cdPesador = this.listaItemPesador[index].cdPesador
            // arrayNovo[j].nmPesador = this.listaItemPesador[index].nmPesador
            // arrayNovo[j].dtRecebimentoFormatado = this.listaItemPesador[index].dtRecebimentoFormatado
            // arrayNovo[j].qtSeparado += this.listaItemPesador[index].qtSeparado
          }
        }
        if (this.stacao === 'E') {
          for (let k = 0; k < arrayNovo.length; k++) {
            let existeItem = false
            for (let p = 0; p < this.listaItemPesador.length; p++) {
              var itemPesadorDelete = this.listaItemPesador[p].cdItem + this.listaItemPesador[p].cdSubItem
              if (arrayNovo[k].item.trim() === itemPesadorDelete) {
                existeItem = true
              }
            }
            if (existeItem === false) {
              arrayNovo.splice(k, 1)
            }
          }
        }
        this.listaItens = arrayNovo
        // debugger
        // for (let index = 0; index < this.listaItens.length; index++) {
        //   if (this.listaItens[index].disabled === false) {
        //     this.recebeuTodos = true
        //     break
        //   }
        // }
        // console.log(this.listaItens)
      },
      async getConferentePesador () {
        this.$store.commit('setSplashScreen', true)
        try {
          await pesadorRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            // debugger
            if (isNaN(this.conferentePesador) === false) {
              var flExiste = false
              for (let index = 0; index < response.data.dados.length; index++) {
                if (parseInt(this.conferentePesador) === response.data.dados[index].cdPesador) {
                  this.cdPesadorSelecionado = response.data.dados[index].cdPesador
                  this.onSalvarItemPesador()
                  flExiste = true
                  break
                }
              }
              // debugger
              if (flExiste === false) {
                this.$swal({
                  icon: 'warning',
                  text: 'Conferente/Pesador não existe!',
                })
              }
            } else {
              this.$swal({
                icon: 'warning',
                text: 'Valor inválido!',
              })
              this.searchConferente = ''
            }
          }).catch((error) => {
            // debugger
            // console.log(error.response.data.detail)
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error.response.data.detail,
            })
            this.conferentePesador = ''
          })
        } catch (error) {
          // debugger
          // console.log(error.response.data.detail)
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
          this.conferentePesador = ''
        }
      },
      onClickAddQtSeparacao (item) {
        this.itemSelecionado = item
        this.dialogItemExpedicao = true
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onInformadaSeparacao (qtSeparado) {
        for (let index = 0; index < this.listaItens.length; index++) {
          if (this.listaItens[index].item === this.itemSelecionado.item) {
            this.listaItens[index].qtSeparado = parseFloat(qtSeparado)
            this.dialogItemExpedicao = false
            break
          }
        }
      },
      async onSalvarItemPesador () {
        if (this.flseparar === false) {
          this.onAddItemPesador()
        }
        if (this.onValidarInserir()) {
          this.$store.commit('setSplashScreen', true)
          await itempesadorRep.Salvar(this.itemPesador).then(response => {
            this.$store.commit('setSplashScreen', false)
            this.conferentePesador = ''
            this.itemPesador = []
            this.$swal({
              icon: 'success',
              text: this.stAcao === 'E' ? 'Devolução Realizada com Sucesso' : 'Separação Realizada com Sucesso',
            })
            this.$emit('onSairExpedicao')
          }).catch(error => {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error.response.data.detail,
            })
          })
        }
      },
      onValidarInserir () {
        if (this.flseparar === false) {
          if (this.selected.length === 0) {
            this.$swal({
              icon: 'warning',
              text: 'Selecione um ou mais produtos para concluir a Entrega!',
            })
            return false
          } else {
            return true
          }
        } else {
          let flInserir = true
          for (let index = 0; index < this.listaItens.length; index++) {
            if ((this.listaItens[index].qtSeparado + parseFloat(this.listaItens[index].qtDigitada.replace('.', '').replace(',', '.'))) > this.listaItens[index].qtVenda) {
              flInserir = false
              this.onMensagem(' A quantidade separada(Qt. Separada + Qt.Saindo) do Produto ' + this.listaItens[index].item + ' é maior que a Qt. Venda!')
              break
            }
          }
          if (flInserir) {
            this.itemPesador = []
            for (let index = 0; index < this.listaItens.length; index++) {
              var totallength = this.listaItens[index].item.trim().length
              let cdItem = 0
              var cdSubItem = this.listaItens[index].item.substring(totallength - 1).trim()
              if (!isNaN(parseInt(cdSubItem))) {
                cdSubItem = ''
                cdItem = this.listaItens[index].item.substring(0, totallength)
              } else {
                cdItem = this.listaItens[index].item.substring(0, totallength - 1)
              }
              this.itemPesador.push({
                cdOrcamento: this.idorcamento,
                cdItem: cdItem,
                cdSubItem: cdSubItem,
                qtSeparado: parseFloat(this.listaItens[index].qtDigitada.replace('.', '').replace(',', '.')),
                cdPesador: this.codigovendedor,
                item: this.listaItens[index].item.trim(),
                cdUsuario: this.$store.getters.usuario.cdusuario,
                dsOper: '-',
                stAcao: this.stacao,
              })
            }
          }
          return flInserir
        }
      },
      onValidarSeparacaoMaterial () {
        // dialogExpedicaoLiberacao = true
        var flExisteSeparacaMenor = false
        var flExisteSeparacaZerada = false
        for (let index = 0; index < this.listaItens.length; index++) {
          if (this.listaItens[index].qtSeparado === 0) {
            flExisteSeparacaZerada = true
          }
        }
        if (flExisteSeparacaZerada === true) {
          this.$swal({
            icon: 'warning',
            text: 'Existe Quantidades que não foram informadas!',
          })
          return false
        }
        for (let index = 0; index < this.listaItens.length; index++) {
          if (this.listaItens[index].qtVenda > this.listaItens[index].qtSeparado) {
            flExisteSeparacaMenor = true
          }
        }
        if (flExisteSeparacaMenor === true) {
          this.$swal({
            icon: 'warning',
            text: 'Existe Quantidade(s) separadas menores que a quantidade vendida, é necessario um LÍDER de Setor para liberar!',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) this.dialogExpedicaoLiberacao = true
          })
        }
      },
      onSelecionarPesador (itemObj) {
        var totallength = itemObj.item.item.trim().length
        var cdItem = 0
        var cdSubItem = itemObj.item.item.substring(totallength - 1).trim()
        if (!isNaN(parseInt(cdSubItem))) {
          cdSubItem = ''
          cdItem = itemObj.item.item.substring(0, totallength)
        } else {
          cdItem = itemObj.item.item.substring(0, totallength - 1)
        }
        if (!this.validarItemExiste(itemObj.item.item.trim())) {
          this.itemPesador.push({
            cdOrcamento: this.idorcamento,
            cdItem: cdItem,
            cdSubItem: cdSubItem,
            qtSeparado: itemObj.item.qtVenda,
            cdPesador: itemObj.idPesador,
            item: itemObj.item.item.trim(),
          })
        } else {
          for (let index = 0; index < this.itemPesador.length; index++) {
            if (this.itemPesador[index].item === itemObj.item.item.trim()) {
              this.itemPesador[index].cdPesador = itemObj.idPesador
              break
            }
          }
        }
      },

      onAddItemPesador () {
        for (let index = 0; index < this.selected.length; index++) {
          var totallength = this.selected[index].item.trim().length
          let cdItem = 0
          var cdSubItem = this.selected[index].item.substring(totallength - 1).trim()
          if (!isNaN(parseInt(cdSubItem))) {
            cdSubItem = ''
            cdItem = this.selected[index].item.substring(0, totallength)
          } else {
            cdItem = this.selected[index].item.substring(0, totallength - 1)
          }
          this.itemPesador.push({
            cdOrcamento: this.idorcamento,
            cdItem: cdItem,
            cdSubItem: cdSubItem,
            qtSeparado: this.selected[index].qtVenda,
            cdPesador: this.cdPesadorSelecionado,
            item: this.selected[index].item.trim(),
            dsOper: '-',
            stAcao: this.stacao,
          })
        }
      },
      estornarseparacao (item) {
        this.itemPesador = []
        if (this.validarEstorno(item)) {
          this.onCarregarEstornoItem(item)
          this.onEnviarEstorno()
        }
      },
      async onEnviarEstorno () {
        await itempesadorRep.Salvar(this.itemPesador).then(response => {
          this.$store.commit('setSplashScreen', false)
          this.conferentePesador = ''
          this.itemPesador = []
          this.get()
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error.response.data.detail,
          })
        })
      },
      validarItemExiste (dados) {
        var exists = this.itemPesador.some(function (objItem) {
          return objItem.item === dados
        })
        return exists
      },
      validarEstorno (item) {
        if (parseFloat(item.qtDigitada.replace('.', '').replace(',', '.')) === 0) {
          this.onMensagem(' Informe uma quantidade ' + item.item + '!')
          return false
        } else if (parseFloat(item.qtDigitada.replace('.', '').replace(',', '.')) > item.qtVenda) {
          this.onMensagem(' A quantidade (Qt.Digitada) do Produto ' + item.item + ' é maior que a Qt. Venda!')
          return false
        }
        return true
      },
      onMensagem (mensagem) {
        this.$swal({
          icon: 'warning',
          text: mensagem,
        })
      },

      onCarregarEstornoItem (item) {
        var totallength = item.item.trim().length
        let cdItem = 0
        var cdSubItem = item.item.substring(totallength - 1).trim()
        if (!isNaN(parseInt(cdSubItem))) {
          cdSubItem = ''
          cdItem = item.item.substring(0, totallength)
        } else {
          cdItem = item.item.substring(0, totallength - 1)
        }
        this.itemPesador.push({
          cdOrcamento: this.idorcamento,
          cdItem: cdItem,
          cdSubItem: cdSubItem,
          qtSeparado: parseFloat(item.qtDigitada.replace('.', '').replace(',', '.')),
          cdPesador: this.codigovendedor,
          item: item.item.trim(),
          cdUsuario: this.$store.getters.usuario.cdusuario,
          dsOper: '+',
          stAcao: this.stacao,
        })
      },
      async onPedirImpressao () {
        this.$store.commit('setSplashScreen', true)
        var dadosImpressao = {
          cdloja: this.lojaselecionada,
          cdOrcamento: this.idorcamento,
          cdUsuario: this.$store.getters.usuario.cdusuario,
        }
        await impressaoRep.Impressao(dadosImpressao).then(response => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'success',
            text: 'Solicitação realizado com sucesso!',
          })
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
    },
  }
</script>
