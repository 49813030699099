<template>
  <v-card>
    <v-container fluid>
      <v-toolbar
        dark
        color="primary"
        class="pl-0 ml-0"
      >
        <v-toolbar-title>
          Permite Finalizar a Separação faltando material?
        </v-toolbar-title>
      </v-toolbar>
      <v-row
        dense
        class="pl-2 pt-5"
      >
        <v-col cols="6">
          <componentepesador />
        </v-col>
      </v-row>
      <v-row
        dense
        class="pl-2 pt-0 mt-0"
      >
        <v-col cols="4">
          <v-text-field
            :append-icon="isVisible ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isVisible ? 'text' : 'password'"
            name="Senha"
            autocomplete="new-password"
            label="Senha"
            hide-details
            solo
            dense
            class="input-group--focused"
            @click:append="isVisible = !isVisible"
          />
        </v-col>
      </v-row>
      <v-row
        dense
        class="pt-5 mt-0"
      >
        <v-card-actions class="pt-0">
          <v-btn
            color="primary accent-4"
          >
            <v-icon left>
              mdi-content-save
            </v-icon> Salvar
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
  import componentepesador from '../componentes/componente-combobox-pesador'
  export default {
    components: {
      componentepesador,
    },
    data () {
      return {
        isVisible: false,
      }
    },
  }
</script>
