import Repository from './repository'

const resource = '/impressao'

export default {

  Impressao (data) {
    return Repository.post(`${resource}`, data)
  },
}
