<template>
  <v-card>
    <v-container fluid>
      <v-toolbar
        dark
        color="primary"
        class="pl-0 ml-0"
      >
        <!-- <v-toolbar-title class="pl-0 ml-0">
        </v-toolbar-title> -->
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onInformadaSeparacao"
        >
          <v-icon class="white--text">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onInformadaSeparacao"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col>
          <h4>Item: {{ item.item }} - {{ item.nmItem }}</h4>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="8"
          md="4"
        >
          <h2 class="primary--text">
            Qtd. Vendido
          </h2>
          <h2>{{ formatPrice(item.qtVenda) }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="8"
          md="4"
        >
          <h2 class="primary--text">
            Qtd. Sepadara
          </h2>
          <v-text-field
            v-model="qtdSeparado"
            v-currency="currency"
            label="Qt. Separada"
            dense
            outlined
            :error-messages="errorSeparacao"
            @blur="validarSeparacao"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
  import expedicaoRep from '../../components/services/expedicaoRepository'
  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },
      pedido: {
        type: Number,
        required: true,
      },
      flpermitealterarpeso: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        currency: {
          locale: 'pt-br',
          currency: null,
          valueAsInteger: false,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
        qtdSeparado: '0,00',
        errorSeparacao: '',
      }
    },
    methods: {

      async onUpdateSeparacao () {
        this.$store.commit('setSplashScreen', true)
        await expedicaoRep.Update(this.pedido, parseFloat(this.qtdSeparado.replace('.', '').replace(',', '.'))).then(response => {
          this.$store.commit('setSplashScreen', false)
          if (response.data.status === 'sucesso') {
            this.$swal({
              icon: 'success',
              text: 'Separação Realizada!',
            })
            return true
          } else if (response.data.status === 'mensagem') {
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
            })
          } else if (response.data.status === 'error') {
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          } else {
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
          return false
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
          return false
        })
      },
      validarSeparacao () {
        var qtVenda = this.item.qtVenda
        var qtSeparadaItem = parseFloat(this.qtdSeparado.replace('.', '').replace(',', '.'))
        if (qtVenda < qtSeparadaItem) {
          this.errorSeparacao = 'Quantidade informada é maior que a Quantidade Vendida!'
          return false
        }
        return true
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onInformadaSeparacao () {
        if (this.validarSeparacao()) {
          if (this.onUpdateSeparacao()) {
            this.$emit('onInformadaSeparacao', this.qtdSeparado)
          }
        }
      },
    },
  }
</script>
