<template>
  <v-select
    v-model="cdPesador"
    :items="item"
    item-text="nmPesador"
    item-value="cdPesador"
    label="Pesador"
    :dense="dense"
    solo
    @change="onSelecionarPesador"
  />
</template>
<script>
  import pesadorRep from '../../components/services/pesadorRepository'
  export default {
    props: {
      supervisor: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: 0,
      },
      itemobj: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        item: [],
        cdPesador: 0,
      }
    },
    created () {
      this.getList()
    },
    methods: {
      async getList () {
        try {
          if (this.supervisor === false) {
            this.$store.commit('setSplashScreen', true)
            await pesadorRep.getList().then(response => {
              this.$store.commit('setSplashScreen', false)
              if (response.data.status === 'sucesso') {
                this.item = response.data.dados
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.$swal({
                icon: 'error',
                text: error,
              })
            })
          } else {
            this.$store.commit('setSplashScreen', true)
            await pesadorRep.getListSupervisor().then(response => {
              this.$store.commit('setSplashScreen', false)
              if (response.data.status === 'sucesso') {
                this.item = response.data.dados
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.$swal({
                icon: 'error',
                text: error,
              })
            })
          }
        } catch (error) {
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
      },
      onSelecionarPesador (id) {
        this.itemObject = {
          idPesador: id,
          item: this.itemobj,
        }
        this.$emit('onSelecionarPesador', this.itemObject)
      },
    },
  }
</script>
